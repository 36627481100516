<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-12 gap-5">
		<!-- Заголовок -->
		<h1 class="flex w-full text-xl h-6 mb-4 sm:mb-7 font-extrabold sm:text-2xl">Справочники Системы</h1>

		<!-- Таблица -->
		<TableGuide />
	</div>
</template>

<script>
// Глобальные данные стора
// import { getters } from '@/store/store';

import TableGuide from './TableGuide.vue';

export default {
	name: 'Main',
	components: { TableGuide }
};
</script>
